import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
// ******** Images ********
import IHCLogo from "../../assets/images/IHCSpecialtyBenefits_logo_Negative-new.svg";
// import BlueSealLogo from "../../assets/images/blue_seal_footer_logo.svg";
// import BradsDeals_logo from "../../assets/images/BradsDeals_logo.png";
// import IHC_logo from "../../assets/images/IHCSB_Affinity_logo_medium_344x140.svg";
import LogoImage from "../LogoImage";
import { useWebsiteData } from "../../context/AppContext";

const Footer = ({ disclaimer }) => {
  // const [affinityFooter, setAffinityFooter] = useState(null);
  // const [affinityData, setAffinityData] = useState();
  // eslint-disable-next-line no-unused-vars
  const { dispatch, state } = useWebsiteData();
  const { wpMenu } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "footer-menu" }) {
        id
        menuItems {
          nodes {
            label
            url
            databaseId
            connectedNode {
              node {
                uri
              }
            }
          }
        }
      }
    }
  `);

  const renderMenuItems = () => {
    if (wpMenu?.menuItems?.nodes) {
      return wpMenu.menuItems.nodes.map((menuItem, i) => {
        const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url;

        const itemId = "menu-item-" + menuItem.databaseId;

        return (
            <div id={itemId} key={i + menuItem.url} className="footer-link">
              <Link to={path}>{menuItem.label}</Link>
            </div>
        );
      });
    }
  };

  return state?.affinityData ? (
      <footer className="affinity-footer">
        <div className="affinity-footer-menu">
          <div className="footer-links">
            <div className="footer-link">
              <Link to="/about-us/">About Us</Link>
            </div>
            <div className="footer-link">
              <Link to="/terms/">Terms of Use</Link>
            </div>
            <div className="footer-link">
              <Link to="/privacy/">Privacy Policy</Link>
            </div>
            <div className="footer-link">
              <Link to="/site-map/">Sitemap</Link>
            </div>
          </div>
        </div>
        <div className="affinity-footer-logos">
          <div className="custom-container">
            <div className="footer-logo-wrapper">
              <div className="footer-logo">
                <LogoImage
                    className="affinity-logo-footer"
                    image={state?.affinityData?.affinity?.logo}
                />
              </div>
              {/* <div className="footer-logo">
              <img width="176px" src={IHC_logo} alt="Ihc" />
            </div> */}
            </div>
          </div>
        </div>
        <div className="affinity-footer-disclaimer">
          {state?.affinityData?.affinity?.affinityDisclaimer ? (
              <>
                <div
                    className="custom-container"
                    dangerouslySetInnerHTML={{
                      __html: disclaimer
                          ? disclaimer
                          : state?.affinityData?.affinity?.affinityDisclaimer,
                    }}
                />
                <div className="footer-copyright">
                  © Copyright {new Date().getFullYear()} IHC Specialty Benefits,
                  Inc. All rights reserved.
                </div>
              </>
          ) : (
              <div className="custom-container">
                <p>
                  IHC Specialty Benefits, Inc., 5353 Wayzata Blvd, Ste. 300, St.
                  Louis Park, MN, 55416 (IHCSB), is an independent licensed
                  insurance agency. THIS IS A SOLICITATION OFINSURANCE BY IHCSB.
                  Contact may be made by an insurance agent/producer or insurance
                  company. Product availability may vary based on state. IHCSB is
                  not affiliated with, connected to, or endorsed by Centers for
                  Medicare and Medicaid Services, including the federal Medicare
                  program. For a complete listing of products, contact
                  1-800-MEDICARE or visit{" "}
                  <a
                      href="https://www.medicare.gov/"
                      target="_blank"
                      rel="noreferrer"
                  >
                    Medicare.gov
                  </a>
                  .
                </p>
                <div className="footer-copyright">
                  © Copyright {new Date().getFullYear()} My Health Insurance, All
                  Rights Reserved
                </div>
              </div>
          )}
        </div>
      </footer>
  ) : (
      <footer>
        <div className="footer-container">
          <div className="footer-links">{renderMenuItems()}</div>
          <div className="footer-logos">
            <div className="footer-logo">
              <img
                  width="176px"
                  src={IHCLogo}
                  alt="IHC Specialty Benefits, Inc."
              />
            </div>
            <div className="footer-logo">
              <a
                  href="https://www.bbb.org/us/mn/saint-louis-park/profile/health-insurance/ihc-specialty-benefits-inc-0704-96344163/#sealclick"
                  target="_blank"
                  rel="nofollow noreferrer"
              >
                <img
                    src="https://seal-minnesota.bbb.org/seals/blue-seal-200-42-whitetxt-bbb-96344163.png"
                    style={{ border: 0 }}
                    alt="IHC Specialty Benefits, Inc. BBB Business Review"
                />
              </a>
            </div>
          </div>
          <div className="footer-text">
            <p>
              IHC Specialty Benefits, Inc., 5353 Wayzata Blvd, Ste. 300, St. Louis
              Park, MN, 55416 (IHCSB), is an independent licensed insurance
              agency. THIS IS A SOLICITATION OF INSURANCE BY IHCSB. Contact may be
              made by an insurance agent/producer or insurance company. Product
              availability may vary based on state. IHCSB is not affiliated with,
              connected to, or endorsed by Centers for Medicare and Medicaid
              Services, including the federal Medicare program. For a complete
              listing of products, contact 1-800-MEDICARE or visit{" "}
              <a
                  href="https://www.medicare.gov/"
                  target="_blank"
                  rel="noreferrer"
              >
                Medicare.gov
              </a>
              . This website is operated by IHCSB and is not the Health Insurance
              Marketplace (
              <a
                  href="https://www.healthcare.gov/"
                  target="_blank"
                  rel="noreferrer"
              >
                HealthCare.gov
              </a>
              ). In offering this website, IHCSB is required to comply with all
              applicable federal laws.
            </p>
          </div>
          <div className="footer-copyright">
            © Copyright {new Date().getFullYear()} IHC Specialty Benefits, Inc.
            All rights reserved.
          </div>
        </div>
      </footer>
  );
};

export default Footer;
